<template>
<div class="home">
        <div class="prosupport" style="background-image: url('./images/prefondo3.png');">
        <h4 style="color:#727376;"><strong>{{$t('Register')}}</strong></h4>
        <div class="p-grid" style="color:#727376;">

			<div class="card p-fluid">
				<h5>Vertical</h5>
				<div class="p-field">
					<label for="name1">Name</label>
					<InputText id="name1" type="text" />
				</div>
				<div class="p-field">
					<label for="email1">Email</label>
					<InputText id="email1" type="text" />
				</div>
				<div class="p-field">
					<label for="age1">Age</label>
					<InputText id="age1" type="text" />
				</div>
			</div>



        </div>
    </div>
</div>
</template>

<script>

export default {
	data() {
		return {

        }
	},
	created() {
	},
	methods: {
	},
}
</script>
